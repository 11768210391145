import React, { useState, useEffect, useRef } from 'react';
import { Form, Image, InputGroup } from 'react-bootstrap';
import { Button } from '@stillfront/stillfront-component-library';
import emailIcon from '../../assets/email.png';
import { useUser } from './../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../utils/config.js';
import './EmailPopUp.scss';

function EmailPopUp() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [verificationFailed, setVerificationFailed] = useState(false);
    const [errorMessageKey, setErrorMessageKey] = useState('');
    const [getTokenFailed, setTokenFailed] = useState(false);
    const [active, setActive] = useState(false);
    const { setEmailValue } = useUser();
    const emailInputRef = useRef(null);

    const validateEmail = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(inputEmail));
    };

    useEffect(() => {
        try {
            CONFIG.fetchAndStoreCsrfToken(); // Fetch and store CSRF token using config
            setTokenFailed(false);
        } catch (error) {
            setTokenFailed(true);
            console.error('Error fetching CSRF token:', error);
        }
        // Set focus to the email input when the component mounts
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
        // Trigger the animation upon mounting and updating
        setActive(false); // Reset to trigger animation
        const timer = setTimeout(() => setActive(true), 5); // Short delay to trigger transition

        // Send tracking event
        CONFIG.sendTrackingEvent({
            stepId: 'ENTER_EMAIL_DIALOG',
            message: 'User reached the enter email for verification step',
        });

        return () => clearTimeout(timer);
    }, []); // this useEffect runs onMount

    const handleEmailChange = (e) => {
        setTokenFailed(false);
        const inputEmail = e.target.value;
        setEmail(inputEmail);
        validateEmail(inputEmail);
    };

    const handleVerifyEmail = async (event) => {
        event.preventDefault();
        setEmailValue(email);
        try {
            await CONFIG.apiCall('/api/v1/auth/verify-email', 'POST', { email });
            setVerificationFailed(false);
            navigate('/verify-code', { state: { email: email } });
        } catch (error) {
            console.error('Error:', error);
            const errorMessageKey = error.status
                ? `errors.${error.status}`
                : 'errors.default';
            setVerificationFailed(true);
            setErrorMessageKey(errorMessageKey)
        }
    };

    return (
        <>
            <Form validated={isValidEmail} className={`form-container ${active ? 'active' : ''}`} onSubmit={handleVerifyEmail}>
                <p className="label-text">{t('verificationCodeSent')}</p>
                <Form.Group className="form-content">
                    <div className="input-wrapper">
                        <InputGroup className='w-auto'>
                            <InputGroup.Text className='input-group-text'>
                                <Image src={emailIcon} alt="Email Icon" className='email-icon' />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                ref={emailInputRef}
                                type="email"
                                aria-label="email"
                                className="email-input"
                                placeholder={t('enterEmail')}
                                value={email}
                                onChange={handleEmailChange}
                                isValid={isValidEmail}
                                isInvalid={!isValidEmail && email !== ''}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('invalidEmailError')}
                            </Form.Control.Feedback>
                        </InputGroup>
                        {getTokenFailed && <p className="token-error-message">{t('tokenErrorMessage')}</p>}
                        {verificationFailed && <p className="error-message" aria-label="error-message">{t(errorMessageKey)}</p>}
                    </div>
                    <Button type="submit" variant="contained" aria-label='email-submit' className='submit-btn'>
                        {t('proceed')}
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
}

export default EmailPopUp;
