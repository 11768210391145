import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@stillfront/stillfront-component-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthModal from '../AuthModal/AuthModal';
import './AbortConfirmModal.scss';

function AbortConfirmModal({show, onHide, onCancel}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleConfirmAbort = () => {
        navigate('/');
        sessionStorage.clear();
    };

    return (
        <>
            <AuthModal
                show={show}
                onHide={onHide}
                showBackButton={false}
                headerTitle="Warning"
                showBanner={false}
                size="md"
            >
                <div className="abort-confirm-container my-4">
                    <p>
                        {t('cancelRegistrationText')}
                    </p>
                    <div className="button-group">
                        <Button variant="outlined" className="me-2" onClick={handleConfirmAbort}>{t('yes')}</Button>
                        <Button variant="contained" className="me-2" onClick={onCancel}>{t('no')}</Button>
                    </div>                
                </div>
            </AuthModal>
        </>
    );
}

AbortConfirmModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onCancel: PropTypes.func,
};

export default AbortConfirmModal;
