import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPopupComponent } from '../../utils/contentMap';
import { useTranslation } from 'react-i18next';
import bannerImage from '../../assets/banner.png';
import AuthModal from '../AuthModal/AuthModal';
import AbortConfirmModal from '../AbortConfirmModal/AbortConfirmModal';
import ROUTES from '../../constants/routes';
import './AuthFlow.scss';

function AuthFlow() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [abortConfirmModalOpen, setAbortConfirmModalOpen] = useState(false);

    // const isAuthenticated = () => {
    //     return !!localStorage.getItem('csrfToken');
    // };

    // useEffect(() => {
    //     if (!isAuthenticated() && location.pathname !== ROUTES.SIGN_IN) {
    //         // If not authenticated and not already on the sign-in page, redirect to sign-in
    //         navigate(ROUTES.SIGN_IN);
    //     }
    // }, [location.pathname, navigate]);

    const handleModalClose = () => {
        setAbortConfirmModalOpen(true); // Show abort confirmation
        setIsModalOpen(false); // Hide parent modal
    };

    const onHandleAbortModalClose = () => {
        setAbortConfirmModalOpen(false); // Hide abort confirmation
        setIsModalOpen(true); // Re-open parent modal
    };

    const getContent = () => {
        const PopupComponent = getPopupComponent(location.pathname);
        return <PopupComponent />;
    };

    //const isModalOpen = location.pathname !== '/';

    const handleBack = () => {
        switch (location.pathname) {
        // case ROUTES.VERIFY_EMAIL:
        //     navigate(ROUTES.SIGN_IN);
        //     break;
        case ROUTES.VERIFY_CODE:
            navigate(ROUTES.VERIFY_EMAIL);
            break;
        case ROUTES.CONSENT:
            navigate(ROUTES.VERIFY_EMAIL);
            break;
        case ROUTES.PRIVACY:
            navigate(ROUTES.CONSENT);
            break;
        default:
            break;
        }
    };

    const headerTitle = t({
        //[ROUTES.SIGN_IN]: 'saveAccount',
        [ROUTES.VERIFY_EMAIL]: 'signInWith',
        [ROUTES.VERIFY_CODE]: 'signInWith',
        [ROUTES.CONSENT]: 'signInWith',
        [ROUTES.PRIVACY]: 'userAgreementTitle',
    }[location.pathname] || 'signInWith');

    // removing sign-in page from the options
    const showBanner = [ROUTES.VERIFY_EMAIL, ROUTES.CONSENT].includes(location.pathname);
    const showCloseButton = ![ROUTES.PRIVACY].includes(location.pathname);
    const showBackButton = [ROUTES.VERIFY_CODE, ROUTES.CONSENT, ROUTES.PRIVACY].includes(location.pathname);
    const showLanguageSwitcher = [ROUTES.VERIFY_EMAIL].includes(location.pathname);

    return (
        <>
            <AuthModal
                show={isModalOpen}
                onHide={handleModalClose}
                size="md"
                showBackButton={showBackButton}
                showLanguageSwitcher={showLanguageSwitcher}
                onBack={handleBack}
                headerTitle={headerTitle}
                showBanner={showBanner}
                bannerSrc={bannerImage}
                showCloseButton={showCloseButton}
                contentKey={location.pathname}
            >
                {getContent()}
            </AuthModal>
            {/* This modal handles the abort registration process */}
            {abortConfirmModalOpen && <div className="dimmed-modal-overlay"></div>}
            <AbortConfirmModal show={abortConfirmModalOpen} onHide={onHandleAbortModalClose} onCancel={onHandleAbortModalClose} />
        </>

    );
}

export default AuthFlow;
